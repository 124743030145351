import React, { useState } from "react";
import s from "./GetBonuses.module.scss";
import titleBg from "./title_bg.svg";
import kw from "./kW.png";
import tbtc from "./tbtc.png";
import tbtcstacking from "./tbtcstacking.png";

import { claimEnergizerProfit, claimMinerProfit } from "../../../http";

const Bonus = ({ title, percentForFrens, percentForTheirFrens, reward, action, onClick, image }) => {
  return (
    <div className={s.grid}>
      <img src={image} className={s.image} alt="Bonus Icon" />
      <div className={s.contentBonus}>
        <div className={s.titleBonus}>{title}</div>
        <div className={s.bonus}>{percentForFrens}% for your frens, {percentForTheirFrens}% for their frens</div>
        <div className={s.flex}>
          <div className={s.reward}>{reward}</div>
          <div onClick={onClick} className={s.takeBonus}>{action}</div>
        </div>
      </div>
    </div>
  );
};

const ConfirmationModal = ({ confirmationText, onClose, onConfirm }) => {
  return (
    <div className={s.modalOverlay}>
      <div className={s.modal}>
        <button className={s.closeBtn} onClick={onClose}>
          <svg
            width="34"
            height="35"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="17"
              cy="17.5"
              r="11.2708"
              transform="rotate(135 17 17.5)"
              stroke="#3BB2E5"
              strokeWidth="1.5"
            />
            <g filter="url(#filter0_d_234_7356)">
              <path
                d="M21.5913 22.0912L16.9945 17.4944M12.3977 12.8977L16.9945 17.4944M16.9945 17.4944L12.3977 22.0912M16.9945 17.4944L21.5913 12.8977"
                stroke="#3BB2E5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_234_7356"
                x="9.64771"
                y="10.1476"
                width="14.6935"
                height="14.6936"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.231373 0 0 0 0 0.698039 0 0 0 0 0.898039 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_234_7356"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_234_7356"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>
        <div className={s.modalTopBar}>
          <h2 className={s.modalTitle}>{"Confirm"}</h2>
        </div>

        <div className={s.text}>{confirmationText}</div>

        <div className={s.modalContent}>
          <button className={s.confirmButton} onClick={onConfirm}>{"Confirm"}</button>
        </div>
      </div>
    </div>
  );
};

const GetBonuses = ({ friendsInfo }) => {
  const [kwCount, setKwCount] = useState(friendsInfo.energizer_profit);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBonus, setCurrentBonus] = useState(null);

  const handleBonusClick = (bonus) => {
    setCurrentBonus(bonus);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentBonus(null);
  };

  const handleConfirm = () => {
    if (currentBonus && currentBonus.onclick) {
      currentBonus.onclick();
    }
    handleCloseModal();
  };
  const ClaimKW = async () => {
    await claimEnergizerProfit()
    setKwCount(0)

  };

  const bonuses = [
    {
      id: 1,
      image: kw,
      onclick: ClaimKW,
      title: "Bonus for energy production",
      percentForFrens: 10, percentForTheirFrens: 5,
      reward: kwCount + " kW",
      action: "Get",
      confirmationText: "Please confirm the deduction of kW from the bonus balance."
    },
    {
      id: 2,
      image: tbtc,
      onclick: null,
      title: "Bonus for mining tBTC",
      percentForFrens: 6,
      percentForTheirFrens: 3,
      reward: "",
      action: "Coming soon",
      confirmationText: "Please confirm the deduction of tBTC from the bonus balance."
    },
    {
      id: 3,
      image: tbtcstacking,
      onclick: null,
      title: "Bonus for staking tBTC",
      percentForFrens: 5,
      percentForTheirFrens: 2,
      reward: "",
      action: "Coming soon",
      confirmationText: "Coming soon"
    },
  ];

  return (
    <div className={s.block}>
      <div className={s.topBar}>
        <h2 className={s.title}>Get Bonuses</h2>
        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title background" width="222" height="40" />
        </div>
      </div>
      <div className={s.content}>
        {bonuses.map(bonus => (
          <Bonus key={bonus.id} {...bonus} onClick={() => handleBonusClick(bonus)} />
        ))}
      </div>
      {isModalOpen && <ConfirmationModal confirmationText={currentBonus.confirmationText} onClose={handleCloseModal} onConfirm={handleConfirm} />}
    </div>
  );
};

export default GetBonuses;
