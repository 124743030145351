import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import Energizen from './Components/Energizen/Energizen';
import Miner from './Components/Miner/Miner';
import Investor from './Components/Investor/Investor';
import Power from './Components/Power/Power';
import Game from './Components/Game/Game';
import Shop from './Components/Shop/Shop';
import Friends from './Components/Friends/Friends';
import Market from './Components/Market/Market';
import { Buffer } from 'buffer';
import { Authorization } from './http';

window.Buffer = Buffer;

const close = () => {
  window.Telegram.WebApp.close();
};

const ErrorModal = () => {
  return (
    <div className="modalOverlay">
      <div className="modal">
        <button className="closeBtn" onClick={close}>
          <svg
            width="34"
            height="35"
            viewBox="0 0 34 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="17"
              cy="17.5"
              r="11.2708"
              transform="rotate(135 17 17.5)"
              stroke="#3BB2E5"
              strokeWidth="1.5"
            />
            <g filter="url(#filter0_d_234_7356)">
              <path
                d="M21.5913 22.0912L16.9945 17.4944M12.3977 12.8977L16.9945 17.4944M16.9945 17.4944L12.3977 22.0912M16.9945 17.4944L21.5913 12.8977"
                stroke="#3BB2E5"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_234_7356"
                x="9.64771"
                y="10.1476"
                width="14.6935"
                height="14.6936"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.231373 0 0 0 0 0.698039 0 0 0 0 0.898039 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_234_7356"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_234_7356"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>
        <div className="modalTopBar">
          <div className="errorContainer">
            <div className="modalTitle">
              {"Unable to verify your account, try again"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Loading = () => (
  <div >
    Loading...
  </div>
);

function App() {
  const hasFetched = useRef(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  // const hash = "vitalikkrasavchik";

  useEffect(() => {
    if (!hasFetched.current) {
      // Authorization(hash)
      Authorization(window.Telegram.WebApp.initData)
        .then((response) => {
          if (response.details === "Failed") {
            setError("Ошибка с юзер айди");
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
      hasFetched.current = true;
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      {error ? (
        <ErrorModal />
      ) : (
        <Routes>
          <Route path="/energizer" element={<Energizen />} />
          <Route path="/miner" element={<Miner />} />
          <Route path="/investor" element={<Investor />} />
          <Route path="/power" element={<Power />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/game" element={<Game />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/market" element={<Market />} />
          <Route path="/tests" element={<Market />} />
          {/* <Route path="/recaptcha" element={<ReCaptcha />} /> */}
        </Routes>
      )}
    </Router>
  );
}

export default App;
