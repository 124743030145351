
import React, { useEffect, useRef } from "react";
import s from "./Bonuses.module.scss";
import titleBg from "./title_bg.svg";
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person2';
import logo from "./logo.png";

const Bonuses = ({ referrals, referralsIsLoading }) => {
  const containerRef = useRef(null);
  const truncateName = (name) => {
    return name.length > 5 ? `${name.slice(0, 5)}...` : name;
  };

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    } else {
      return num.toString();
    }
  };

  if (referralsIsLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={s.block} ref={containerRef}>
      <div className={s.topBar}>
        <h2 className={s.title}>Friends List</h2>
        <div className={s.backgroundTrapezoid}>
          <img src={titleBg} alt="title bg" width="222" height="40" />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.headerContainer}>{"Name"}</div>
        <div className={s.headerContainer}>
          <div className={s.title}>{"kW"}</div>
          <div className={s.profitPersent}>
            <PersonIcon className={s.icon} /> 10% <GroupIcon className={s.icon} /> 5%
          </div>
          <div className={s.subtext}>{"rewards"}</div>
        </div>
        <div className={s.headerContainer}>
          <div className={s.title}>{"tBTC"}</div>
          <div className={s.profitPersent}>
            <PersonIcon className={s.icon} /> 6% <GroupIcon className={s.icon} /> 3%
          </div>
          <div className={s.subtext}>{"rewards"}</div>
        </div>
        <div className={s.headerContainer}>{"Asics"}</div>
      </div>
      <div className={s.referrals}>
        {referrals.map((referral, index) => (
          <div className={s.referralRow} key={referral.friend_id}>
            <div className={s.referralCell}>
              {index + 1}
              <img src={logo} alt="logo" style={{ margin: "-5%" }}></img>
              {truncateName(referral.friend_name)}
            </div>
            <div className={s.referralCell}>
              <div className={s.flex}>
                <PersonIcon className={s.icon} />
                +{formatNumber(referral.first_level_refs_energy)}
              </div>
              <div className={s.flex}>
                <GroupIcon className={s.icon} />
                +{formatNumber(referral.second_level_refs_energy)}
              </div>
            </div>
            <div className={s.referralCell}>
              <div className={s.flex}>
                <PersonIcon className={s.icon} />
                +{formatNumber(referral.first_level_refs_coins)}
              </div>
              <div className={s.flex}>
                <GroupIcon className={s.icon} />
                +{formatNumber(referral.second_level_refs_coins)}
              </div>
            </div>
            <div className={s.referralCell}>{formatNumber(referral.asics_count)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bonuses;
